<template>
  <div>
    <b-row>
      <b-col>
        <h2 class="text-nowrap">
          Case:
          <router-link
            :to="'/cases/' + caseObject.CaseID + '/detail'"
          >
            {{ caseObject.FileNumber }}</router-link>
          - {{ caseObject.ClosedByUserID ? 'Closed' : 'Open' }}
        </h2>
      </b-col>
    </b-row>
    <hr>
    <div>
      <h2>Add Budget Adjustment</h2>
    </div>
    <div>
      <b-row>
        <b-col md="2">
          <b-form-group
            label="Budget Hours"
            class="required-data"
            label-for="budgetHours"
          >
            <b-form-input
              id="budgetHours"
              v-model="caseObject.BudgetHours"
              type="number"
              placeholder=""
              name="budgetHours"
              readonly
            />
          </b-form-group>
        </b-col>
        <b-col md="2">
          <b-form-group
            label="Budget $"
            label-for="budget"
          >
            <b-form-input
              id="budget"
              v-model="caseObject.BudgetDollars"
              type="number"
              placeholder=""
              name="budget"
              readonly
            />
          </b-form-group>
        </b-col>
        <b-col md="2">
          <b-form-group
            label="Authorized Hours"
            label-for="authorizedHours"
          >
            <b-form-input
              id="authorizedHours"
              v-model="caseObject.BudgetHours"
              type="text"
              placeholder=""
              name="authorizedHours"
              readonly
            />
          </b-form-group>
        </b-col>
        <b-col md="2">
          <b-form-group
            label="Hours Remaining"
            label-for="hoursRemaining"
          >
            <b-form-input
              id="hoursRemaining"
              v-model="caseObject.BudgetRemainingHours"
              type="text"
              placeholder=""
              name="hoursRemaining"
              readonly
            />
          </b-form-group>
        </b-col>
      </b-row>
      <validation-observer ref="simpleRules">
        <hr>
        <b-row>
          <b-col md="3">
            <validation-provider
              #default="{ errors }"
              name="Budget Adjustment"
              rules="required"
            >
              <b-form-group
                label="Budget Hours (Adj.)"
                class="required-data"
                label-for="budgetHours"
                :state="errors.length > 0 ? false : null"
              >
                <b-form-input
                  id="budgetHours"
                  v-model="BudgetAdjustmentHours"
                  type="number"
                  placeholder=""
                  name="budgetHours"
                />
                <small
                  class="text-danger"
                  :state="errors.length > 0 ? false : null"
                >
                  {{ errors[0] }}
                </small>
              </b-form-group>
            </validation-provider>
          </b-col>
        </b-row>
        <b-row>
          <b-col md="3">
            <b-form-group
              label="Budget $ (Adj.)"
              label-for="budget"
            >
              <b-form-input
                id="budget"
                v-model="BudgetAdjustmentDollars"
                type="number"
                placeholder=""
                name="budget"
              />
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col md="3">
            <b-form-group
              label="Budget Notes"
              label-for="budgetnotes"
            >
              <b-form-input
                id="budgetnotes"
                v-model="Notes"
                type="text"
                placeholder=""
                name="budgetnotes"
              />
            </b-form-group>
          </b-col>
        </b-row>
        
        <div>
          <b-button
            class="p-1"
            type="reset"
            variant="outline-secondary"
            size="md"
            @click="noBackToCase()"
          >
            Cancel
          </b-button>&nbsp;
          <b-button
            class="p-1"
            type="submit"
            variant="primary"
            size="md"
            @click="reopenCase()"
          >
            Save
          </b-button>
        </div>
      </validation-observer>
    </div>
  </div>
</template>

<script>
import {BButton, BCol, BFormGroup, BFormInput, BRow} from "bootstrap-vue";
import APIService from "@core/utils/APIService";
import {ValidationObserver, ValidationProvider} from "vee-validate";
import { required } from '@validations'

const apiService = new APIService();

export default {
  components: {
    BRow,
    BCol,
    BButton,
    BFormGroup,
    BFormInput,
    ValidationProvider,
    ValidationObserver,
  },
  data() {
    return {
      caseObject: {},
      BudgetAdjustmentDollars: 0,
      BudgetAdjustmentHours: 0,
      Notes: "",
    }
  },
  created() {
    this.getCase();
  },
  methods: {
    getCase() {
      const CaseID = this.$route.params.caseId;
      apiService
          .get("case/" + CaseID + "/detail")
          .then((res) => {
            this.caseObject = res.data;
            if(this.caseObject.ClosedByUserID) {
              this.isClosing = false;
            }
          })
    },
    processForm() {
      const postData =  {
        "DollarsAdjustment": this.BudgetAdjustmentDollars,
        "HoursAdjustment": this.BudgetAdjustmentHours,
        "Notes": this.Notes
      }
      apiService
        .post("case/" + this.caseObject.CaseID + "/budget/adjust", postData)
        .then( res => {
          this.$router.push('/cases/' + this.caseObject.CaseID + "/detail");
        })

    },
    noBackToCase() {
      this.$router.push('/cases/' + this.caseObject.CaseID + "/detail");
    },
    remindOnDate(val) {
      let d = new Date();
      d.setMonth(d.getMonth() + val);
      this.RemindDate = d.toISOString().substr(0, 10);
    },
    setReminder() {
      const postData = {
        "RemindOnDate": this.RemindDate,
        "Notes": this.Notes,
      }
      apiService
          .post("case/" + this.caseObject.CaseID + "/reopen-reminder", postData)
          .then( () => {
            this.$router.push('/cases/' + this.caseObject.CaseID + "/detail");
          })
    },
    reopenCase() {
      this.$refs.simpleRules.validate().then((success) => {
        if (success) {
          this.processForm();
        }
      });
    },
  },
}
</script>

<style scoped>

</style>